import React from 'react';
import MealPlanList from './MealPlansList'
import PageHeader from '../PageComponents/PageHeader';
import '../../Content/css/freeMealPlans.css';
import AuthUserContext from '../Authentication/AuthUserContext';
import Helmet from 'react-helmet';
import { connect } from "react-redux";
let gravURL = process.env.REACT_APP_GRAV_URL;
let siteURL = process.env.REACT_APP_APP_URL;
const mapStateToProps = (state, props) => {
    return {user: state.user, uid: state.uid, pageContext: props.pageContext};
};
class FreeMealPlans extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.state = {
			gravContent: this.props.pageContext.frontmatter,
			uploads: gravURL + '/uploads/images/',
			width: 0,
			height: 0,
			loading: true,
			mealPlans: this.props.pageContext.mealPlans,
			headContent: this.props.pageContext.head,
		}
	}
	componentDidMount(){
		this._isMounted = true;
		if(this._isMounted === true){
			
			this.setState({loading: false})
		}
		
	}
	componentWillUnmount() {
	  this._isMounted = false;
	}

	render() {
		let {
			keyWords,
			facebookTitle,
			facebookDesc,
			shareImg,
			googleDesc,
		} = this.state.headContent;
		return (
			<main>
				<Helmet>					
					<title>
						Free Meal Plans | Ketogenic Programme | New Image Keto
					</title>	
		            {googleDesc&&
		            	<meta name="description" content={this.state.headContent.googleDesc} />
		            }
		            {keyWords&&
		            	<meta name="keywords" content={this.state.headContent.keyWords.toString()} />
		            }
		            <meta property="og:image:width" content="1200" />
		            <meta property="og:image:height" content="630" />
		           	<meta property="og:url" content={siteURL} />
		            	
		            {facebookTitle?
		            	<meta property="og:title" content={this.state.headContent.facebookTitle}/>
		            	:
		            	<meta property="og:title" content={this.state.headContent.title}/>
		            }
		            {facebookTitle?
		            	<meta name="twitter:title" content={this.state.headContent.facebookTitle}/>
		            	:
		            	<meta property="twitter:title" content={this.state.headContent.title}/>
		            }
		            {facebookDesc?
		            	<meta property="og:description" content={this.state.headContent.facebookDesc} />
		            	:
		            	<meta property="og:description" content={this.state.headContent.googleDesc} />
		            }
		      		{facebookDesc?
		            	<meta name="twitter:description" content={this.state.headContent.facebookDesc}/>
		            	:
		            	<meta property="twitter:description" content={this.state.headContent.googleDesc} />
		            }
		            {shareImg&&
		            	<meta property="og:image" content={gravURL + this.state.headContent.shareImg} />
		        	}
		        	{shareImg&&
		            	<meta property="og:image:url" content={gravURL + this.state.headContent.shareImg}/>
		        	}
		           	{shareImg&&
		            	<meta name="twitter:image" content={gravURL + this.state.headContent.shareImg}/>
		        	}
		        	{shareImg&&
		            	<meta name="twitter:card" content={gravURL + this.state.headContent.shareImg}/>
		        	}
			    </Helmet>
				
				
				<section>
				<PageHeader
					mainHeading={this.state.gravContent.pageMainHeading}
					subHeading={this.state.gravContent.pageSubHeading}
				/>
				</section>
				
				<AuthUserContext.Consumer>
		            {authUser => authUser 
		              ? <MealPlanList mealPlans={this.state.mealPlans} user={true}/>
		              : <MealPlanList mealPlans={this.state.mealPlans} user={false}/>
		            }
		        </AuthUserContext.Consumer>
				
				
			</main>
		);
	}
}
const ConnectedMealPlans = connect(mapStateToProps, null)(FreeMealPlans);
export default ConnectedMealPlans;